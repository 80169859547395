import { Shadows, TextVariants } from "@sixty-six-north/ui-system"
import { useProductListing } from "category/ProductListing"
import { ColorCheckboxWrapper } from "filters/ColorCheckboxWrapper"
import { CheckboxFilter, CheckboxFilterSection } from "filters/FilterComponent"
import { createRef, RefObject, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"

export const FiltersMenu = (props: {
  triggerRef?: RefObject<HTMLButtonElement>
  onClose?: () => void
}) => {
  const productListing = useProductListing()
  const { t } = useTranslation("category")
  const wrapperRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        props.onClose &&
        wrapperRef.current &&
        props.triggerRef?.current &&
        event.target &&
        !wrapperRef.current.contains(event.target) &&
        !props.triggerRef?.current.contains(event.target)
      ) {
        props.onClose()
      }
    }
    if (props.onClose) {
      document.addEventListener("mousedown", handleOutsideClick)
    }
    return () => document.removeEventListener("mousedown", handleOutsideClick)
  }, [wrapperRef])

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "Escape": {
        if (props.onClose) props.onClose()
        props.triggerRef?.current?.focus()
        event.preventDefault()
        break
      }
    }
  }

  return (
    <Flex
      ref={wrapperRef}
      sx={{
        position: "absolute",
        top: "70px",
        bg: "white.0",
        flexWrap: "wrap",
        gap: [36, null, null, 48, 72],
        width: "100%",
        justifyContent: ["space-between", null, null, null, "center"],
        p: 48,
        zIndex: 9,
        boxShadow: Shadows.small,
        border: "1px solid",
        borderColor: "grey.4",
        maxHeight: "620px",
        overflow: "auto"
      }}
      onKeyDown={props.onClose && handleKeyDown}
    >
      <CheckboxFilterSection
        filterLabel={t("style")}
        filterName={"style"}
        values={productListing.styles}
        toggleFilter={it => productListing.filter.toggleStyle(it.filterValue)}
      />
      <CheckboxFilterSection
        filterLabel={t("color")}
        filterName={"colors"}
        values={productListing.colors}
        toggleFilter={it => productListing.filter.toggleColor(it.filterValue)}
      >
        {productListing.colors.map(it => {
          return (
            <ColorCheckboxWrapper
              key={it.filterValue}
              colors={productListing.subColors[it.filterValue]}
            >
              <CheckboxFilter
                key={it.filterValue}
                filterName={"colors"}
                filter={it}
                toggleFilter={() =>
                  productListing.filter.toggleColor(it.filterValue)
                }
                labelVariant={`text.${TextVariants.label3Bold}`}
              />
            </ColorCheckboxWrapper>
          )
        })}
      </CheckboxFilterSection>

      {productListing.functionalities.length > 0 && (
        <CheckboxFilterSection
          filterLabel={t("functionality")}
          filterName={"functionality"}
          values={productListing.functionalities}
          toggleFilter={it =>
            productListing.filter.toggleFunctionality(it.filterValue)
          }
        />
      )}

      <CheckboxFilterSection
        filterLabel={t("size")}
        filterName={"size"}
        values={productListing.sizes}
        toggleFilter={it => productListing.filter.toggleSize(it.filterValue)}
      />
    </Flex>
  )
}
