import {
  Label3,
  Radio,
  Shadows,
  TextVariants
} from "@sixty-six-north/ui-system"
import { useProductListing } from "category/ProductListing"
import { OrderBy } from "product/ProductDalTypes"
import { createRef, RefObject, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"

export const SortMenu = (props: {
  triggerRef: RefObject<HTMLButtonElement>
  onClose: () => void
}) => {
  const { t } = useTranslation("category")
  const productListing = useProductListing()
  const wrapperRef = createRef<HTMLDivElement>()

  useEffect(() => {
    const handleOutsideClick = event => {
      if (
        wrapperRef.current &&
        props.triggerRef.current &&
        event.target &&
        !wrapperRef.current.contains(event.target) &&
        !props.triggerRef.current.contains(event.target)
      ) {
        props.onClose()
      }
    }
    document.addEventListener("mousedown", handleOutsideClick)
    return () => document.removeEventListener("mousedown", handleOutsideClick)
  }, [wrapperRef])

  const handleKeyDown = (event: React.KeyboardEvent) => {
    switch (event.key) {
      case "Escape": {
        props.onClose()
        props.triggerRef.current?.focus()
        event.preventDefault()
        break
      }
    }
  }

  return (
    <Flex
      ref={wrapperRef}
      onKeyDown={handleKeyDown}
      sx={{
        position: "absolute",
        top: "71px",
        width: "240px",
        flexDirection: "column",
        gap: "1rem",
        p: 24,
        backgroundColor: "white.0",
        boxShadow: Shadows.small
      }}
    >
      <Flex
        sx={{
          position: "absolute",
          width: "24px",
          height: "24px",
          left: "calc(50% - (24px/2));",
          top: "-10px",
          background: "white.0",
          transform: "rotate(45deg)"
        }}
      />
      <Radio
        id="lowest-price"
        name="price"
        value={OrderBy.priceAsc}
        checked={productListing.ordering === OrderBy.priceAsc}
        onClick={() => productListing.setOrdering(OrderBy.priceAsc)}
      >
        <Label3 sx={{ pl: 4, variant: `text.${TextVariants.label3Bold}` }}>
          {t("lowestPrice", { defaultValue: "Price low to high" })}
        </Label3>
      </Radio>
      <Radio
        id="highest-price"
        name="price"
        value={OrderBy.priceDesc}
        checked={productListing.ordering === OrderBy.priceDesc}
        onClick={() => productListing.setOrdering(OrderBy.priceDesc)}
      >
        <Label3 sx={{ pl: 4, variant: `text.${TextVariants.label3Bold}` }}>
          {t("highestPrice", { defaultValue: "Price high to low" })}
        </Label3>
      </Radio>
    </Flex>
  )
}
